<!--
 * @Description: 无权访问提示页面
 * @version: 
 * @Author: PSG
 * @Date: 2021-06-21 10:25:15
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-14 17:24:14
-->
<template>
  <Header
    :isShowLogo="true"
    :isForbidLogoClick="true"
  />
  <div class="invaild">
   <div class="content">
     <div class="wrapper">
      <img class='icon' src="@/assets/images/invalid.png" alt="">
      <p class="text">即将开放</p>
      <p class="tips">感谢您的关注!</p>
    </div>
   </div>
  </div>
</template>

<script>
import Header from '@/components/common/Header'

export default {
  components: {
    Header,
  },
  /**
   * 程序入口
   */
  setup () {
    return {
    }
  }
}
</script>

<style lang='less' scoped>
.invaild {
  position: absolute;
  top: 56px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background-color: @backgroundColor;
  padding: 20px;
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-width: 1000px;
    min-height: 600px;
    background-color: #fff;
    border: 1px solid #fff;
    box-sizing: border-box;
    .icon {
      display: block;
      width: 120px;
      height: 120px;
      margin: 0 auto;
      text-align: center;
    }
    .text {
      margin-top: 16px;
      color: #7F7F88;
      font-size: 26px;
      text-align: center;
    }
    .tips {
      margin-top: 16px;
      color: #7F7F88;
      font-size: 16px;
      text-align: center;
    }
  }
}
</style>